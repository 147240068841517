import React, { useState, useEffect } from "react";
import axios from "axios";
import InfiniteScroll from "react-infinite-scroll-component";
const UserList = () => {
  const [users, setUsers] = useState([]);
  const [lastId, setLastId] = useState(0);
  const [tempId, setTempId] = useState(0);
  const [limit, setLimit] = useState(20);
  const [keyword, setKeyword] = useState("");
  const [query, setQuery] = useState("");
  const [hasmore, setHasmore] = useState(true);

  useEffect(() => {
    getUsers();
  }, [lastId, keyword]);

  const getUsers = async () => {
    const response = await axios.get(
      `http://infinite-scroll.uluumbch.my.id/users?search_query=${keyword}&lastId=${lastId}&limit=${limit}`,
      {
        headers: {
          "Content-Type": "application/json",
        },
        mode: "cors",
      }
      )
    // convert axios.get to fetch
    // const response = await fetch(
    //   `http://infinite-scroll.uluumbch.my.id/users?search_query=${keyword}&lastId=${lastId}&limit=${limit}`
    // ).catch((err) => {
    //   console.log("Error: ", err);
    // });
    // const data = await response.json();
    // console.log(data);
    

    const newUsers = response.data.result;

    setUsers([...users, ...newUsers]);
    setTempId(response.data.lastId);
    setHasmore(response.data.hasMore);
  };

  const fetchMore = () => {
    setLastId(tempId);
  };

  const searchData = (e) => {
    e.preventDefault();
    setLastId(0);
    setUsers([]);
    setKeyword(query);
  };

  return (
    <div className="w-full mx-auto max-w-7xl ">
      <form onSubmit={searchData} className="py-5">
        <label
          htmlFor="default-search"
          className="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-gray-300"
        >
          Search
        </label>
        <div className="relative">
          <div className="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
            <svg
              className="w-5 h-5 text-gray-500 dark:text-gray-400"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
              ></path>
            </svg>
          </div>
          <input
            value={query}
            onChange={(e) => setQuery(e.target.value)}
            type="text"
            id="default-search"
            className="block p-4 pl-10 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 focus:outline-none"
            placeholder="type something here..."
          />
          <button
            type="submit"
            className="text-white absolute right-2.5 bottom-2.5 bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
          >
            Search
          </button>
        </div>
      </form>
      <InfiniteScroll

        className="min-h-screen"
        dataLength={users.length}
        next={fetchMore}
        hasMore={ hasmore }
        loader={
          <span className="text-3xl mx-auto dark:text-slate-200">
            Loading...
          </span>
        }
      >
        <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
          <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400 p-8 ">
            <thead className="text-lg text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
              <tr>
                <th className="text-center">No</th>
                <th>ID</th>
                <th>Name</th>
                <th>Email</th>
                <th>Gender</th>
              </tr>
            </thead>
            <tbody className="text-lg">
              {users.map((user, index) => (
                <tr
                  key={index}
                  className="border-b dark:bg-gray-800 dark:border-gray-700 odd:bg-white even:bg-gray-50 odd:dark:bg-gray-800 even:dark:bg-gray-700"
                >
                  <td className="text-center">{index + 1}</td>
                  <td>{user.id}</td>
                  <td>{user.name}</td>
                  <td>{user.email}</td>
                  <td>{user.gender}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </InfiniteScroll>
    </div>
  );
};

export default UserList;
